$color_text: #666;
$color_primary: #DDD;
$color_secondary: #999;
$color_danger: #ff0000;
$color_border: #4473F5;
$color_disabled: #ebebeb;

$border_radius: 0;
$border_width: 1px;

$padding_top_bottom: 10px;
$padding_left_right: 10px;

$font_size_floating_label: 12px;

$font__size_tab_heading: 20px;
$font__size_modal_heading: 24px;