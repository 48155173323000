body {
    position: relative;
    overflow-x: hidden;
    font-family: $font_family_primary;
    font-weight: 300;
    font-size: 17px;
    line-height: 1.7;
    background-color: $color_gray;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        background-color: transparent;
        @include transition(background 300ms ease-in-out);
    }
    
    &.bg-over {
        backface-visibility: hidden;

        &:before {
            z-index: 99;
            background-color: rgba($color_black, .8);
            @include transition(background 300ms ease-in-out);
        }
    }

    &.fixed-body {
        position: fixed;
        display: block;
        max-width: 100vw;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 136px;
    }
    
    // @include mq_tablet {
    //     padding-top: 110px;
    // }
}

a {
    text-decoration: none;
    @include transition(all 300ms ease-in-out);

    @include hover-state {
        text-decoration: none;
        @include transition(all 300ms ease-in-out);
    }
}

blockquote {
    @extend .text-standout;
}

select {
    padding-left: 5px!important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.hide {
    display: none!important;
}

.container-extended {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1300px;
}

.overflow-hidden, .oh {
    position: relative;
    overflow: hidden;
}

.max-width {

    &-center {
        margin-left: auto;
        margin-right: auto;

        &--700 {
            @extend .max-width-center;
            max-width: 700px;
        }

        &--900 {
            @extend .max-width-center;
            max-width: 900px;
        }
    }

    &-left {
        margin-right: auto;

        &--700 {
            @extend .max-width-left;
            max-width: 700px;
        }

        &--900 {
            @extend .max-width-left;
            max-width: 900px;
        }
    }
    
    &-right {
        margin-left: auto;

        &--900 {
            @extend .max-width-right;
            max-width: 900px;
        }

        &--900 {
            @extend .max-width-right;
            max-width: 900px;
        }
    }
}

.display-flex {
    display: flex;

    @include mq_mobile {
        display: block;
    }
}

.alcenter {
    align-items: center;
}

.underlined-anchor a {
    text-decoration: underline;
}

.border {

    &-left {
        border-left: 6px solid;

        &--primary {
            @extend .border-left;
            border-left-color: $color_primary;
        }

        &--secondary {
            @extend .border-left;
            border-left-color: $color_secondary;
        }
    }

    &-top {
        
        &--gray {
            border-top: 1px solid $color_border_light;
        }

        &--primary {
            border-top: 1px solid $color_primary;
        }

        &--secondary {
            border-top: 2px solid $color_secondary;
        }
    }

    &-radius {
        border-radius: 6px;
    }
}

.color {

    &--primary {
        color: $color_primary;
    }

    &--secondary {
        color: $color_secondary;
    }

    &--white {
        color: $color_white!important;
    }
}

.bg-color {

    &--primary {
        color: $color_white;
        background-color: $color_primary;

        a {
            color: $color_white;
        }
    }

    &--secondary {
        color: $color_white;
        background-color: $color_secondary;

        a {
            color: $color_white;
        }
    }
    
    &--black {
        color: $color_white;
        background-color: $color_black;

        a {
            color: $color_white;
        }
    }

    &--blue {
        color: $color_white;
        background-color: $color_blue_dark;

        a {
            color: $color_white;
        }
    }

    &--white {
        color: $color_text_primary;
        background-color: $color_white;
    }

    &--gray {
        color: $color_text_primary;
        background-color: $color_gray;
    }
}

.bg-offset {
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $color_white;
    }

    &--right {
        @extend .bg-offset;

        &:before {
            right: -100%;
        }
    }

    &--left {
        @extend .bg-offset;

        &:before {
            left: -100%;
        }
    }

    &--full {
        @extend .bg-offset;

        &:before {
            left: -100%;
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -100%;
            width: 100%;
            height: 100%;
            background-color: $color_white;
        }
    }
}

.bg-overlay {
    position: relative;

    > div {
        position: relative;
        z-index: 2;
    }

    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba($color_white, .8);
    }

    &--primary {
        @extend .bg-overlay;

        &:after {
            background-color: rgba($color_primary, .8);
        }
    }

    &--secondary {
        @extend .bg-overlay;

        &:after {
            background-color: rgba($color_secondary, .8);
        }
    }
    
    &--black {
        @extend .bg-overlay;

        &:after {
            background-color: rgba($color_black, .8);
        }
    }

    &--blue {
        @extend .bg-overlay;

        &:after {
            background-color: rgba($color_blue_dark, .8);
        }
    }

    &--gray {
        @extend .bg-overlay;

        &:after {
            background-color: rgba($color_gray, .8);
        }
    }
}

.bg-img {
    background-position: center left;
    background-size: cover;
    // background-image: url(/images/angle180/bg-geo.png);
}

.text {

    &-uc {
        text-transform: uppercase;
    }

    &-center {
        text-align: center;
    }

    &-left {
        text-align: left;
    }
}

.separator {
    position: relative;

    &:after {
        content: "";
        display: block;
        height: 3px;
        width: 50px;
        margin-top: 10px;
        margin-bottom: 30px;
        background-color: $color_primary;
    }

    &-white {
        @extend .separator;

        &:after {
            background-color: $color_white;
        }
    }

    &-secondary {
        @extend .separator;

        &:after {
            background-color: $color_secondary;
        }
    }

    &-center {
        @extend .separator;
        text-align: center;

        &:after {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.box-shadow {
    -webkit-box-shadow: 0px 5px 10px -5px rgba($color_border_light, 1);
    -moz-box-shadow: 0px 5px 10px -5px rgba($color_border_light, 1);
    box-shadow: 0px 5px 10px -5px rgba($color_border_light, 1);
}

.link-social {

    img {
        opacity: 1;
        @include transition(opacity 300ms ease-in-out);
    }

    @include hover-state {

        img {
            opacity: .6;
            @include transition(opacity 300ms ease-in-out);
        }
    }
}

.text-social {
    position: relative;

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: -6px;
        left: 0;
        height: 40px;
        width: 1px;
        background-color: $color_border_light;
    }

    @include mq_tablet {
        font-size: 13px;
    }
}

.text-standout {
    font-size: 20px;
    line-height: 1.6;
    margin: 30px;

    strong {
        color: $color_secondary;
    }
}

.quote {
    position: relative;
    padding-left: 80px;
    color: $color_text_secondary;

    &:before {
        content: '“';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        font-family: 'Arial';
        font-size: 200px;
        line-height: 160px;
        color: lighten($color_border_light, 10%);
    }
}

.contact-item {
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;

    a {
        color: $color_text_primary;
    }

    i {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 20px;
    }
}

.work-item {
    text-align: center;

    i {
        font-size: 40px;
    }

    p {
        font-weight: bold;
        font-family: $font_family_secondary;
        line-height: 1.4;
    }
}

.link-pdf {
    position: relative;
    display: inline-block;
    padding-left: 50px;

    i {
        position: absolute;
        top: 7px;
        left: 0;
        font-size: 40px;
    }
}

.commercial-properties .padding-inner {

    .font-heading--medium {
        text-align: center;
    }
}

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100, 125, 150); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
    @each $side in $sides {
        .m-#{str-slice($side, 0, 1)}-#{$space} {
            margin-#{$side}: #{$space}px!important;
        }

        .p-#{str-slice($side, 0, 1)}-#{$space} {
            padding-#{$side}: #{$space}px!important;
        }
    }
}

.padding {

    &-inner {
        padding: 30px;
        
        @include mq_mobile_portrait {
            padding: 30px 15px;
        }
    }
}

@media screen and (max-width: 991px) {

    .m-t-100 {
        margin-top: 50px!important;
    }

    .m-b-100 {
        margin-bottom: 50px!important;
    }

    .p-t-100 {
        padding-top: 50px!important;
    }

    .p-b-100 {
        padding-bottom: 50px!important;
    }

    .p-t-75 {
        padding-top: 40px!important;
    }

    .p-b-75 {
        padding-bottom: 40px!important;
    }
}

.magicwall li a.colorbox:hover{
    background:#9c3!important;
}

.inline-icon {
    position: relative;
    padding-left: 30px;

    i {
        position: absolute;
        top: 0.35em;
        left: 0;
        height: 20px;
        width: 20px;
    }
}

.icon-enter {
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(/images/angle180/enter.svg);
}

.h-dot-block {
    padding-left:2.125rem;
}

.h-dot {
    margin-top: 30px!important;

    &:before {
        content: "\2022";
        position: relative;
        top: 0.175em;
        font-size: 2em;
        line-height: 0.7;
        padding-right: .125em;
        color: $color_secondary;
    }

    &:after {
        display:none!important;
    }

    &.h4 {

        &:before {
            top: 0.125em;
        }
    }
}

.faq {

    &-q {
        position: relative;
        padding-left: 40px;

        &:before {
            content: "Q";
            display: inline-block;
            position: absolute;
            top: .2rem;
            left: 0;
            height: 30px;
            width: 30px;
            line-height:30px;
            text-align: center;
            color: $color_white;
            border-radius: 50%;
            background-color: $color_secondary;
            margin-right: 10px;
            font-family: $font_family_secondary;
            font-weight: 500;
            font-size: 22px;
        }

        &:after {
            display: none!important;
        }
    }

    &-a {
        position: relative;
        padding-left: 40px;

        &:before {
            content: "A";
            display: inline-block;
            position: absolute;
            top: .05rem;
            left: 0;
            height: 30px;
            width: 30px;
            line-height:30px;
            text-align: center;
            color: $color_white;
            border-radius: 50%;
            background-color: $color_primary;
            margin-right: 10px;
            font-family: $font_family_secondary;
            font-weight: 500;
            font-size: 22px;
        }

        &:after {
            display: none!important;
        }
    }
}

.numbers-item {
    position: relative;
    padding-left: 40px;

    [data-number]:before {
        content: attr(data-number);
        display: inline-block;
        position: absolute;
        top: .15rem;
        left: 0;
        height: 30px;
        width: 30px;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: $color_white;
        border-radius: 50%;
        margin-right: 10px;
        background-color: $primary;
    }
}

.drop-item-wrap {
    padding-left: 30px;
}

.drop-item {
    position: relative;
    padding-left: 30px;

    &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        font-size: 2em;
        line-height: 0.7;
        padding-right: .35em;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(/images/angle180/icon-drop.svg);
        @include transform(translateY(-50%));
    }
}

.dot-item-wrap {
    padding-left: 30px;
}

.dot-item {
    position: relative;
    margin: 20px 0 10px; 

    &:before {
        content: "\2022";
        position: absolute;
        top: 50%;
        top: calc(50% - 2px);
        left: -.75em;
        font-size: 1.5em;
        line-height: 0;
        color: $color_secondary;
        @include transform(translateY(-50%));
    }

    &--top {
        @extend .dot-item;
        line-height: 1.4;

        &:before {
            top: 1.25rem;
            @include transform(translateY(0));
        }
    }
}
