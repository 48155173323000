.three-boxes {
    margin-top: -100px;
    padding-bottom: 50px;

    .box-expand {
        position: relative;
        padding: 50px;
        height: 100%;
        margin: 0 -15px 0;
        background-color: $color_white;
        // outline: 0px solid $color_white;
        border-bottom: 3px solid $color_secondary;
        @include transform(scale(1));
        @include transition(all 300ms ease-in-out);

        .box-title {
            @extend .font-heading--medium;
            @extend .separator-secondary;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &.border-sides {
            border-left: 1px solid $color_border;
            border-right: 1px solid $color_border;
        }

        @include media-breakpoint-up(lg) {

            &:hover {
                z-index: 2;
                @include transform(scale(1.1));
                @include transition(all 300ms ease-in-out);
            }
        }

        .btn-block {
            text-align: right;
        }
    }

    @media screen and (max-width: 991px) {
        padding-bottom: 0;

        .col-lg-4 {

            &:first-of-type {

                .box-expand {
                    margin: 0 -15px 20px;
                }
            }
        }

        .box-expand {
            height: auto!important;
            margin: 0 -15px 20px;

            &.border-sides {
                border-left: none;
                border-right: none;
            }
        }
    }

    @include mq_mobile_portrait {

        .box-expand {
            padding: 30px 15px;
        }
    }
}