.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $z_menu_desktop;
    font-family: $font_family_secondary;
    @include transition(all 300ms ease-in-out);

    &.minified {
        top: -42px;
        @include transition(all 300ms ease-in-out);

        .header-bottom {
            border-bottom: 1px solid $color_border_light;
        }

        @media screen and (max-width: 991px) {
            top: 0;
        }
    }

    .header-top {
        text-align: right;
        overflow: hidden;
        max-height: 41px;

        &-border {
            border-bottom: 1px solid $color_border_light;
        }

        .menu {
            text-align: left;
            line-height: 40px;

            > div {
                display: inline-block;

                a {
                    color: $color_text_primary;

                    @include hover-state {
                        color: $color_primary;
                    }
                }
                
                &:not(:last-child) {
                    margin-right: 20px;
                    
                    @media (min-width: 768px) and (max-width: 1199px) {
                        margin-right: 10px;
                    }
                }
            }
        }

        .header-top--items {

            .header-top--item {
                @extend .font-paragraph--small;
                font-family: $font_family_primary;
                display: inline-block;
                vertical-align: top;
                height: 40px;
                padding: 4px 20px;
                text-align: left;
                border-left: 1px solid $color_border_light;

                > * {
                    line-height: 1.4;
                }

                i {
                    position: relative;
                    top: 2px;
                    font-size: 18px;
                    margin-right: 4px;
                    color: $color_primary;
                }

                a {
                    color: $color_text_primary;

                    @include hover-state {
                        color: $color_primary;
                    }
                }
            }

            .header-top--btn {
                display: inline-block;

                @include mq_tablet {
                    display: none;
                }

                button {
                    padding: 6px 20px;
                }
            }
        }
    }

    .header-bottom {
        // overflow: hidden;
        // max-height: 91px;
        padding: 18px 0;
        border-bottom: 1px solid transparent;

        &:after {
            content: "";
            display: block;
            clear: both;
        }

        .header-logo {
            float: left;

            .header-logo-mobile {
                display: none;
            }
        }

        .header-main-menu {
            float: right;
            margin-top: 6px;
        }
    }

    @include mq_tablet {

        .header-top {

            .menu {
                font-size: 13px;
            }
        }

        .header-bottom {
            padding: 10px 0;

            .header-logo {
                float: left;

                img {
                    max-width: 150px!important;
                }
            }

            .header-main-menu {
                margin-top: 0;
            }
        }
    }

    // @include mq_mobile {
    @media screen and (max-width: 991px) {

        .header-top {
            // display: none;
            height: 0;
            overflow: hidden;

            .header-top--items {

                > div:nth-child(2) {
                    position: fixed;
                    top: 6px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    border: none;
                    z-index: 3;

                    a {
                        
                        @include hover-state {
                            color: initial;
                        }
                    }
                }
            }
        }

        .header-bottom {
            position: relative;
            overflow: hidden;
            padding: 10px 0;
            height: 50px;
            max-width: 540px;
            margin: 0 auto;
            border-bottom: 1px solid $color_border_light;

            .header-logo {

                .header-logo-desktop {
                    display: none;
                }

                .header-logo-mobile {
                    display: block;
                }

                img {
                    max-width: 120px!important;
                }
            }

            .header-main-menu {
                display: none;
                // position: fixed;
                // top: 0;
                // right: -100%;
                // height: 100%;
                // width: 290px;
                // margin: 0;
                // background: $color_white;

                // .hey-dropdown-menu {

                //     > div {
                //         display: block;
                //     }
                // }
            }
        }
    }

    @media (min-width: 350px) and (max-width: 575px) {

        .header-top {

            .header-top--items {

                > div:nth-child(2) {
                    left: auto;
                    right: 40px;
                }
            }
        }
    }

    @media screen and (max-width: 349px) {

        .header-top {

            .header-top--items {

                > div:nth-child(2) {
                    left: auto;
                    right: 30px;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {

    .header-logo-desktop {
        max-width: 220px!important;
    }

    .text-social {
        padding-left: 12px!important;
    }
}