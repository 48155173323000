@import 'variables.scss';
@import 'node_modules/bootstrap/scss/bootstrap-reboot.scss';
@import 'node_modules/bootstrap/scss/bootstrap-grid.scss';

@import 'mixins.scss';
@import 'fonts.scss';
@import 'base.scss';
@import 'buttons.scss';
@import 'animations.scss';
@import 'icons.scss';

@import 'modules/header.scss';
@import 'modules/hero.scss';
@import 'modules/boxes.scss';
@import 'modules/menu-desktop.scss';
@import 'modules/form.scss';
@import 'modules/sidebar.scss';
@import 'modules/breadcrumbs.scss';
@import 'modules/main-content.scss';

@import 'pages/blog.scss';

@include mq_desktop {

    .clk-phone,
    [href*="tel:"] {
        pointer-events: none;
        cursor: default;
    }
}
