$color_primary          : #9c3;
$color_secondary        : #4473F5;

$color_white            : #ffffff;
$color_black            : #000000;
$color_gray             : #F5F6F8;
$color_gray_dark        : #535252;
$color_blue_dark        : #030e28;

$color_text_primary     : #333;
$color_text_secondary   : #101010;

$color_border           : #bbb;
$color_border_dark      : #aaa;
$color_border_light     : #dfdfdf;

$color_anchor_primary   : #4473F5;
$color_anchor_secondary : #9c3;

$z_menu_desktop         : 500;
$z_menu_mobile          : 600;
$z_modal                : 700;

$font_family_primary    : 'Roboto', sans-serif;
$font_family_secondary  : 'Dosis', sans-serif;

$width_extra_small      : 567px;
$width_small            : 767px;
$width_medium           : 991px;
$width_large            : 1023px;
$width_extra_large      : 1200px;

// bootstrap overrides
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px
);
