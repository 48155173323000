@mixin shadow($left:3px, $top:13px, $blur:40px, $spread:-8px, $color:rgba(0,0,0,0.4), $inset:"") {
    -webkit-box-shadow: $left $top $blur $spread $color #{$inset};
    -moz-box-shadow:    $left $top $blur $spread $color #{$inset};
    box-shadow:         $left $top $blur $spread $color #{$inset};
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin transform($transforms) {
    -moz-transform:    $transforms;
    -o-transform:      $transforms;
    -ms-transform:     $transforms;
    -webkit-transform: $transforms;
    transform:         $transforms;
}

@mixin scale($var){
    -webkit-transform: scale($var);
    -moz-transform:    scale($var);
    -ms-transform:     scale($var);
    -o-transform:      scale($var);
    transform:         scale($var);
}

@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content; 
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	} 
}

@mixin transition-timing-function($easing) {
	-webkit-transition-timing-function: $easing;
	-moz-transition-timing-function: $easing;
	-o-transition-timing-function: $easing;
	-ms-transition-timing-function: $easing;
	transition-timing-function: $easing;
}

@mixin transform-style($val) {
	-webkit-transform-style: $val;
	-moz-transform-style: $val;
	-ms-transform-style: $val;
	transform-style: $val;
}

@mixin hover-state {
    &:hover, &:active, &:focus {
        @content;
    }
}

@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin flex-direction($value: row) {
	@if $value == row-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: horizontal;
	} @else if $value == column {
		-webkit-box-direction: normal;
		-webkit-box-orient: vertical;
	} @else if $value == column-reverse {
		-webkit-box-direction: reverse;
		-webkit-box-orient: vertical;
	} @else {
		-webkit-box-direction: normal;
		-webkit-box-orient: horizontal;
	}
	-webkit-flex-direction: $value;
	-moz-flex-direction: $value;
	-ms-flex-direction: $value;
	flex-direction: $value;
}

@mixin justify-content($value: flex-start) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;		
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}

@mixin align-self($value: auto) {
	// No Webkit Box Fallback.
	-webkit-align-self: $value;
	-moz-align-self: $value;
	@if $value == flex-start {
		-ms-flex-item-align: start;
	} @else if $value == flex-end {
		-ms-flex-item-align: end;
	} @else {
		-ms-flex-item-align: $value;
	}
	align-self: $value;
}

@mixin mq_desktop {

    @media (min-width: #{$width_large + 2}) {
        @content;
    }
}

@mixin mq_big_tablet {

    @media (min-width: #{$width_medium + 1}) and (max-width: #{$width_extra_large}) {
        @content;
    }
}

@mixin mq_tablet {

    @media (min-width: #{$width_small + 1}) and (max-width: #{$width_medium}) {
        @content;
    }
}

@mixin mq_mobile {

    @media screen and (max-width: #{$width_small}) {
        @content;
    }
}

@mixin mq_mobile_landscape {

    @media (min-width: #{$width_extra_small + 1}) and (max-width: #{$width_small}) {
        @content;
    }
}

@mixin mq_mobile_portrait {

    @media screen and (max-width: #{$width_extra_small}) {
        @content;
    }
}