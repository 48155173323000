.page-blog-layout {

    .post-content {

        h1, h2 {
            @extend .separator;
        }
    
        h2, h3, h4 {
            margin-top: 30px;
        }
    
        h3 {
            font-size: 28px;
            line-height: 1.4;
            margin-bottom: 20px;
        }
    
        h4 {
            font-size: 26px;
        }

        h5 {
            font-size: 24px;
            // font-size: $font_family_primary;
            font-weight: bold;
        }
    
        ul.list-drop {
            list-style: none;
            padding-left: 2em;
            margin: 2rem 0;
    
            li {
                position: relative;
                margin-bottom: 10px;
    
                &:before {
                    // content: "\2022";
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: .1em;
                    left: -1.5rem;
                    font-size: 2em;
                    line-height: 0.7;
                    padding-right: .35em;
                    height: 20px;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url(/images/angle180/icon-drop.svg);
                    // color: #4473F5;
                }
    
                h3 {
                    display: inline-block;
                    margin-top: 0!important;
                    margin-bottom: .5rem!important;
    
                    &:after {
                        margin-bottom: 0;
                    }
                }
            }
    
            &.list-h3 {
    
                li {
                    margin-bottom: 1rem;

                    &:before {
                        top: .35em;
                    }
                }
            }
        }
    
        img {
            margin: 20px 0;
            max-width: 100%;
            height: auto;
        }
    
        iframe {
            width: 100%;
        }
    }


    .colorlist {
        margin: 2rem 0;

        &-item {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 12.5rem;
            margin-bottom: 3rem;

            .c {
                flex: none;
                height: 12.5rem;
                width: 100%;
                max-width: 12.5rem;
                margin-right: 2rem;

                &1 {
                    background-color: #114E88;
                }
                &2 {
                    background-color: #ABB6A6;
                }
                &3 {
                    background-color: #A98A5E;
                }
                &4 {
                    background-color: #EDEAE9;
                }
                &5 {
                    background-color: #E5DCCD;
                }
                &6 {
                    background-color: #CAD4D7;
                }
                &7 {
                    background-color: #C5D9D0;
                }
                &8 {
                    background-color: #D7E6EA;
                }
                &9 {
                    background-color: #DACDBA;
                }
                &10 {
                    background-color: #CBCCC8;
                }
            }
        }
    }

    .colorlist--inline {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 2rem 0;

        > p {
            width: 100%;
            margin: 0!important;
        }

        &-item {
            flex: 1;
            position: relative;
            height: 12.5rem;
            width: 12.5rem;
            margin-bottom: 1rem;
            margin-right: 2rem;
            text-align: center;
            overflow: hidden;

            > p {
                width: 100%;
                margin: 0!important;
            }

            .c {
                height: 12.5rem;
                width: 100%;
                max-width: 12.5rem;
                margin-right: 2rem;

                &11 {
                    background-color: #E7E5E1;
                }
                &12 {
                    background-color: #D4D1C5;
                }
                &13 {
                    background-color: #CBDDE7;
                }
                &14 {
                    background-color: #EFEAE6;
                }
                &15 {
                    background-color: #9C6C43;
                }
                &16 {
                    background-color: #493D3B;
                }
                &17 {
                    background-color: #DE786C;
                }
                &18 {
                    background-color: #F6E8B5;
                }
                &19 {
                    background-color: #F0E2DF;
                }
            }
        }
    }

    @include mq_mobile_portrait {

        .colorlist {

            &-item {
                display: block;
            }
        }

        .colorlist--inline {
            display: block;
        }
    }
}

.latest-blog-post {

    &:not(:last-child) {
        margin-bottom: 50px;
    }

    .post-img {

        img {
            margin: 0;
            height: 350px;
        }
    }

    .post-title {

        a {
            color: $color_text_primary;

            &:hover {
                color: $color_anchor_secondary;
            }
        }
    }

    .post-content {
        position: relative;
        max-width: 590px;
        margin-top: -80px;
        margin-left: auto;
        z-index: 2;
    }

    @media screen and (max-width: 991px) {

        .post-content {
            margin-top: 0;
            max-width: 100%;
        }

        .post-content {
            margin-top: 0;
            max-width: 100%;
        }
    }
}

.read-more {
    font-family: $font_family_secondary;
}

.post-sidebar {

    ul {

        li {
            padding-bottom: 10px;
            margin-bottom: 30px;
            border-bottom: 1px solid $color_gray_dark;

            .title {
                line-height: 1.4;
            }
        }
    }
}

.theadline {
    background-color: #efefef;
    padding: 10px 20px;
    font-weight: bold;
}

.tcontent {
    padding: 10px 20px 10px 20px;
}

.heading-number {
    position: relative;
    padding-left: 2.5rem;

    &:before {
        content: attr(data-number);
        display: inline-block;
        position: absolute;
        top: .15rem;
        left: 0;
        height: 30px;
        width: 30px;
        font-size: initial;
        font-weight: 300;
        line-height: 30px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        background-color: #4473F5;
        margin-right: 10px;
    }
}

.heading-drop {
    position: relative;
    padding-left: 2.5rem;

    &:before {
        content: attr(data-number);
        display: inline-block;
        position: absolute;
        top: .5rem;
        left: .5rem;
        font-size: 2em;
        line-height: 0.7;
        padding-right: .35em;
        height: 20px;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url(/images/angle180/icon-drop.svg);
    }

    &.h4:before {
        top: .75rem;
    }
}

.content-indent {
    padding-left: 2.5rem;
}

.list-toc {
    list-style: none;
    counter-reset: list-toc-counter;

    > li {
        position: relative;

        &:before {
            counter-increment: list-toc-counter;
            content: counter(list-toc-counter) ".";
            position: absolute;
            top: 0;
            left: -1.5rem;
        }

        ul {

            > li {

                a {

                    @include mq_mobile {
                        display: flex;
                    }
                }
            }
        }
    }
}