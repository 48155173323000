// @import "../../../../../modules/mod_leadsinquiries/assets/dev/sass/plugins/checkbox.scss";

@import "../../../../../modules/mod_leadsinquiries/assets/dev/sass/_variables.scss";
@import "../../../../../modules/mod_leadsinquiries/assets/dev/sass/_mixins.scss";
@import "../../../../../modules/mod_leadsinquiries/assets/dev/sass/animations.scss";
@import "../../../../../modules/mod_leadsinquiries/assets/dev/sass/form.scss";
$color_border: #bbb;

.form-sidebar {
    padding: 30px;
    margin-top: -150px;
    
    @include mq_tablet {
        margin-top: 0;
    }

    @include mq_mobile {
        margin-top: 0;
    }

    input, select, textarea {
        background-color: $color_white!important;
    }

    &.contact-page {

        .btn-full--secondary {
            background-color: $color_primary;

            &:hover {
                background-color: darken($color_primary, 10%);
            }
        }
    }
}

.linq-form {

    .results-box.active, 
    .active.waiting-box, 
    .active.response-box {
        background-color: rgba(255,255,255,0.9);
    }

    .response-message {
        font-size: 22px;
        font-family: $font_family_secondary;
        color: $color_text_primary;
    }

    .response-box {

        .reset-btn {
            width: initial;
        }
    }
}