.hey-dropdown-nav {
    // max-width: 850px;

    $show_drow_arrows:          true;

    // MENU ROW
    $menu_font_size:            19px;
    $menu_font_weight:          300;
    $menu_line_height:          20px;

    $menu_text_color:           #000;
    $menu_text_hover_color:     $color_primary;
    $menu_text_active_color:    $color_primary;

    $menu_bg_hover_color:       false;
    $menu_bg_active_color:      false;

    $menu_padding:              0;
    $menu_margin:               0 0 0 24px;

    // Border
    $menu_bb_width:             false;       // false for none
    $menu_bb_type:              solid;
    $menu_bb_color:             transparent;
    $menu_bb_hover_color:       transparent;
    $menu_bb_active_color:      $color_primary;


    // DROPBOX
    $dropbox_font_size:         17px;
    $dropbox_font_weight:        300;
    $dropbox_line_height:       25px;

    $dropbox_bg_color:          #FFF;
    $dropbox_text_color:        #000;
    $dropbox_text_hover_color:  $color_primary;
    $dropbox_text_actve_color:  $color_primary;

    $dropbox_bg_hover_color:    false;

    $dropbox_padding:           0;
    $dropbox_margin:            0;

    $dropbox_item_padding:      3px 20px;
    $dropbox_item_margin:       5px;


    // Dropbox width
    $dropbox_lvl1_width:         275px;
    $dropbox_lvl2_width:         275px;
    $dropbox_lvl3_width:         275px;

    // Indentation
    $dropbox_lvl1_top:          100%;
    $dropbox_lvl1_left:         0;

    $dropbox_lvl2_top:          0;
    $dropbox_lvl2_left:         275px;

    $dropbox_lvl3_top:          0;
    $dropbox_lvl3_left:         275px;

    // Animations
    $fx_speed:                  300ms;
    $dropdown_fade_speed:       0;


    display: block;

    // Meniu Row
    .hey-dropdown-menu {
        * { background-clip: padding-box; }

        margin: 0;
        padding: 0;

        > div {
            display: inline-block;
            position: relative;

            padding: 14px 0;
            margin: $menu_margin;

            font-family: $font_family_secondary;
            font-size: $menu_font_size;
            line-height: $menu_line_height;
            font-weight: $menu_font_weight;

            > a, > span.hey-nav-separator {
                display: inline-block;
                text-decoration: none;
                text-transform: uppercase;

                padding: $menu_padding;

                color: $menu_text_color;

                @if $menu_bb_width { border-bottom: $menu_bb_width $menu_bb_type $menu_bb_color; }

            }

            > a:hover, > span.hey-nav-separator:hover {
                color: $menu_text_hover_color;
                @if $menu_bg_hover_color { background-color: $menu_bg_hover_color; }
                @if $menu_bb_width { border-bottom: $menu_bb_width $menu_bb_type $menu_bb_hover_color; }
            }

            // Active Menu Row Item
            &.active {
                > a, > span.hey-nav-separator {
                    color: $menu_text_active_color;
                    @if $menu_bg_active_color { background-color: $menu_bg_active_color; }
                    @if $menu_bb_width { border-bottom: $menu_bb_width $menu_bb_type $menu_bb_active_color; }
                }
            }

            @media (min-width: 992px) and (max-width: 1199px) {
                font-size: 17px;
                margin: 0 0 0 10px;
            }

            @include mq_tablet {
                font-size: 12px;
                margin: 0 0 0 10px;
            }
        }
    }

    .hey-dropdown-box {
        position: absolute;

        padding: $dropbox_padding;
        margin: $dropbox_margin;

        font-size: $dropbox_font_size;
        font-weight: $dropbox_font_weight;
        line-height: $dropbox_line_height;
        text-align: left;

        background-color: $dropbox_bg_color;
        box-shadow: 0 0 5px rgba(0,0,0, 0.1);
        border-top: 3px solid $color_primary;

        z-index: 999;

        > div {
            position: relative;
            margin: 0 20px;

            @include mq_tablet {
                // padding: 10px;
                font-size: 14px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $color_border;
            }

            &.parent > a {

                &:before {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    font-size: 10px;
                    @include transform(translateY(-50%));
                }
            }

            > a, > span.hey-nav-separator {
                display: block;
                position: relative;
                padding: 20px 0;

                color: $dropbox_text_color;
                text-decoration: none;
            }

            > a:hover, > span.hey-nav-separator:hover {
                color: $dropbox_text_hover_color;
                @if $dropbox_bg_hover_color { background-color: $dropbox_bg_hover_color; }

                &:before {
                    color: $dropbox_text_hover_color;
                }
            }

            &.active {
                > a, > span.hey-nav-separator {
                    color: $dropbox_text_actve_color;

                    &:before {
                        color: $dropbox_text_hover_color;
                    }
                }
            }
        }
    }

    // Defaults
    .hey-dropdown-menu {
        > div {

            // Level 1
            > .hey-dropdown-box {
                top: $dropbox_lvl1_top;
                left: $dropbox_lvl1_left;
                width: $dropbox_lvl1_width;
            }

            // Level 2
            div > .hey-dropdown-box {
                top: $dropbox_lvl2_top;
                left: $dropbox_lvl2_left;
                width: $dropbox_lvl2_width;
            }

            // Level 3
            div div > .hey-dropdown-box {
                top: $dropbox_lvl3_top;
                left: $dropbox_lvl3_left;
                width: $dropbox_lvl3_width;
            }
        }
    }

    // Mechanics
    div {

        & > div.hey-dropdown-box {
            opacity: 0;
            visibility: hidden;
            @include transition( visibility step-end $dropdown_fade_speed, opacity $dropdown_fade_speed linear 0s );
        }

        &:hover > .hey-dropdown-box {
            opacity: 1;
            visibility: visible;
            @include transition( visibility step-start 0s, opacity $dropdown_fade_speed linear 0s );
        }
    }

    a, span.hey-nav-separator {
        @include transition( all $fx_speed );
    }

    /* Parent Arrow */
    @if $show_drow_arrows {
        .hey-dropdown-menu {

            > div.deeper.parent {
                z-index: 3;
            }

            > div.deeper.parent > a,
            > div.deeper.parent > span.hey-nav-separator {
                position: relative;
                padding-right: 23px;

                &:before, &:after {
                    content: "";
                    position: absolute;
                    width: 6px;
                    height: 1px;
                    right: 0px;

                    background-color: $menu_text_color;

                    top: 50%;
                    right: 7px;
                    margin: 1px 0 0;

                    @include transition( all $fx_speed );
                    // @include transform-origin( 50% );

                    @include mq_tablet {
                        top: calc(50% - 2px);
                    }
                }

                &:before {
                    right: 11px;
                    @include transform( rotate(50deg) );
                }

                &:hover:before {
                    background-color: $menu_text_hover_color;
                    @include transform( rotate(-50deg) );
                }

                &:after {
                    @include transform( rotate(-50deg) );
                }

                &:hover:after {
                    background-color: $menu_text_hover_color;
                    @include transform( rotate(50deg) );
                }
            }
            
            > div.deeper.parent.active > a {
                
                &:before,
                &:after {
                    background-color: $menu_text_hover_color;
                }
            }
        }
    }

    .deeper.parent > .hey-nav-separator .item-title,
    .deeper.parent > a .item-title {
        position: relative;

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            top: -10px;
            width: 40px;
            height: 70px;
            background: #fff;
            opacity: 0;
        }

        &:before {
            left: -20px;
            @include transform(rotate(45deg));
        }

        &:after {
            right: -30px;
            @include transform(rotate(-45deg));
        }

        &:hover {
            z-index: 5;
        }
    }

    .item-note {
        display: block;
    }
}

$md_first_item: 196;
$md_1daypaint_item: 186;

@media screen and (min-width: 768px) {

    .hey-dropdown-nav .hey-dropdown-menu > div.item-127 .item-title:before,
    .hey-dropdown-nav .hey-dropdown-menu > div.item-128 .item-title:before {
        display: none!important;
    }

    .hey-dropdown-nav .hey-dropdown-menu > div.item-122,
    .hey-dropdown-nav .hey-dropdown-menu > div.item-132 {
        position: static;

        > span {
            position: relative;
        }

        > .hey-dropdown-box {
            display: none!important;
            z-index: -1;
        }

        &:hover {

            > .hey-dropdown-box {
                display: flex!important;
                z-index: 10;
            }
        }

        .hey-dropdown-box {

            > div {
                border: none;

                &.item-#{$md_first_item} {

                    &.parent.active {

                        a {
                            .item-note {
                                color: $color_text_primary!important;
                            }
                        }
                    }

                    a {
                        position: relative;

                        .item-title {
                            font-size: 21px;

                            &:after {
                                content: "";
                                display: block;
                                height: 2px;
                                width: 30px;
                                margin-top: 8px;
                                background-color: $color_secondary;
                            }
                        }

                        .item-note {
                            font-size: 15px;
                            margin-top: 12px;
                        }
                    }
                }

                &.item-119,
                &.item-120 {

                    .item-title {
                        font-size: 19px;
                    }
                }

                &.item-199 {
                    margin: 0 15px!important;
                }

                // &.item-#{md_1daypaint_item} {
                &.item-200 {

                    a {

                        .item-title {

                            &:after {
                                content: ">";
                                display: inline-block;
                                margin-left: 5px;
                                color: $color_primary;
                            }
                        }
                    }
                }

                &.item-257 {
                    padding-top: 15px;
                }

                &.item-186 {
                    padding-top: 15px;
    
                    a {
                        position: relative;
    
                        &:before {
                            content: "1-DAY";
                            display: inline-block!important;
                            position: relative;
                            top: -2px;
                            padding: 5px;
                            line-height: 1;
                            margin-right: 5px;
                            border-radius: 4px;
                            font-size: 9px;
                            color: $color_white;
                            background-color: $color_secondary;
                        }
                    }
                }
            }
        }

        > .hey-dropdown-box {
            display: flex;
            flex-wrap: wrap;
            left: 0;
            right: 0;
            width: 100%;
            margin: -20px auto 0;
            max-width: 1110px;
    
            > div {
                // float: left;
                // width: calc(50% - 40px);
                // width: -webkit-calc(50% - 40px);
                flex: 0 0 50%;
                max-width: 50%;
                margin: 0;

                a {
                    font-weight: 500;

                    .item-note {
                        font-family: $font_family_primary;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 1.4;
                        margin-top: 2px;
                        color: $color_text_primary!important;
                    }

                    .btn-arrow {
                        color: $color_secondary;
                    }
                }

                .side-right {

                    + .hey-dropdown-box {
                        height: 100%;
                        background-color: $color_gray;
                    }
                }

                .box-services {
                    text-transform: uppercase;
                    color: #888!important;
                    padding-bottom: 10px;

                    + .hey-dropdown-box {
                        overflow: hidden;

                        > div {
                            float: left;
                            width: 50%;
                            padding-right: 20px;
                            // width: calc(50% - 40px);
                            // width: -webkit-calc(50% - 40px);
                            margin: 0;

                            a {
                                position: relative;
                                padding-top: 0;

                                .item-title {
                                    position: relative;
                                    display: inline-block;

                                    &:after {
                                        content: ">";
                                        display: inline-block;
                                        margin-left: 5px;
                                        color: $color_primary;
                                    }
                                }
                            }
                        }
                    }
                }

                .box-other-services {

                    + .hey-dropdown-box {
                        overflow: hidden;
                        background: transparent;

                        > div {
                            float: left;
                            width: 50%;
                            margin: 0;

                            &:nth-child(odd) {
                                padding-right: 15px;
                            }

                            &:nth-child(even) {
                                padding-left: 0;
                            }

                            a {
                                // padding-top: 0;
                                // padding-bottom: 10px;
                                padding-top: 5px!important;
                                padding-bottom: 5px!important;

                                .item-title {
                                    position: relative;
                                    display: inline-block;

                                    &:after {
                                        content: ">";
                                        display: inline-block;
                                        margin-left: 5px;
                                        color: $color_primary;
                                    }
                                }

                                @include mq_big_tablet {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }

                .side-left {

                    + .hey-dropdown-box {
                        padding: 40px 20px;

                        > div {

                            &:first-child {
                                
                                a {
                                    padding-top: 0;
                                }
                            }
                        }
                    }
                }

                .side-right {

                    + .hey-dropdown-box {
                        padding: 40px 20px;

                        > div {
                            
                            &:last-child {
                                clear: both;
                                padding-top: 30px;
                                margin-top: 30px;
                                border-top: 1px solid $color_border;

                                a {
                                    position: relative;
                                    padding-top: 0;
                                    padding-left: 60px;

                                    &:before {
                                        content: "";
                                        display: inline-block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        height: 40px;
                                        width: 40px;
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        background-image: url('/images/angle180/icon-paint2.svg');
                                    }

                                    .item-note {
                                        max-width: 310px;
                                    }
                                }
                            }
                        }
                    }
                }

                .hey-dropdown-box {
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    border: none;
                    box-shadow: none;
                    width: 100%;
                }
            }
        }
    }

    .hey-dropdown-nav .hey-dropdown-menu > div.item-140 {
        position: static;

        > span {
            position: relative;
        }

        > .hey-dropdown-box {
            display: none!important;
            z-index: -1;
        }

        &:hover {

            > .hey-dropdown-box {
                display: flex!important;
                z-index: 10;
            }
        }

        .hey-dropdown-box {

            > div {
                border: none;

                &.item-287 {

                    &.parent.active {

                        a {
                            .item-note {
                                color: $color_text_primary!important;
                            }
                        }
                    }

                    .btn-arrow {
                        display: block;
                    }

                    a {
                        position: relative;

                        .item-title {
                            font-size: 21px;

                            &:after {
                                content: "";
                                display: block;
                                height: 2px;
                                width: 30px;
                                margin-top: 8px;
                                background-color: $color_secondary;
                            }
                        }

                        .item-note {
                            font-size: 15px;
                            margin-top: 12px;
                        }
                    }
                }
            }
        }

        > .hey-dropdown-box {
            display: flex;
            flex-wrap: wrap;
            left: 0;
            right: 0;
            width: 100%;
            margin: -20px auto 0;
            max-width: 1110px;
    
            > div {
                // float: left;
                // width: calc(50% - 40px);
                // width: -webkit-calc(50% - 40px);
                flex: 0 0 50%;
                max-width: 50%;
                margin: 0;

                a {
                    font-weight: 500;

                    .item-note {
                        font-family: $font_family_primary;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 1.4;
                        margin-top: 2px;
                        color: $color_text_primary!important;
                    }

                    .btn-arrow {
                        color: $color_secondary;
                    }
                }

                .side-right {

                    + .hey-dropdown-box {
                        height: 100%;
                        max-height: 490px;
                        overflow-y: scroll;
                        padding: 40px 20px;
                        background-color: $color_gray;

                        &::-webkit-scrollbar {
                            width: 10px;
                        }

                        &::-webkit-scrollbar-track {
                            background:  linear-gradient(0deg, darken($color_primary, 10%) 0%, $color_primary 100%);
                        }

                        &::-webkit-scrollbar-thumb {
                            background: transparent; /* opacity: 0; should do the thing either */
                            box-shadow: 0px 0px 0px 100000vh $color_gray;
                        }

                        > .divider:nth-child(2) {
                            margin-top: 30px;
                        }
                    }
                }

                .box-services {
                    text-transform: uppercase;
                    color: #888!important;
                    padding-bottom: 10px;

                    + .hey-dropdown-box {
                        overflow: hidden;

                        > div {
                            float: left;
                            width: 50%;
                            padding-right: 20px;
                            // width: calc(50% - 40px);
                            // width: -webkit-calc(50% - 40px);
                            margin: 0;

                            a {
                                position: relative;
                                padding-top: 0;

                                .item-title {
                                    position: relative;
                                    display: inline-block;

                                    &:after {
                                        content: ">";
                                        display: inline-block;
                                        margin-left: 5px;
                                        color: $color_primary;
                                    }
                                }

                                @media (min-width: 768px) and (max-width: 1199px) {
                                    .item-title {
                                        font-size: 15px;
                                    }
                                    
                                    .item-note {
                                        font-size: 13px;
                                    }
                                }
                            }
                        }
                    }
                }

                .box-other-services {

                    + .hey-dropdown-box {
                        overflow: hidden;
                        background: transparent;

                        > div {
                            float: left;
                            width: 50%;
                            margin: 0;

                            &:nth-child(odd) {
                                padding-right: 15px;
                            }

                            &:nth-child(even) {
                                padding-left: 0;
                            }

                            a {
                                // padding-top: 0;
                                // padding-bottom: 10px;
                                padding-top: 5px!important;
                                padding-bottom: 5px!important;

                                .item-title {
                                    position: relative;
                                    display: inline-block;

                                    &:after {
                                        content: ">";
                                        display: inline-block;
                                        margin-left: 5px;
                                        color: $color_primary;
                                    }
                                }

                                @include mq_big_tablet {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }

                .side-left {

                    + .hey-dropdown-box {
                        padding: 40px 20px;

                        > div {

                            &:first-child {
                                
                                a {
                                    padding-top: 0;
                                }
                            }

                            &.deeper {
                                
                                > .hey-dropdown-box {
                                    
                                    > div {

                                        &:nth-child(odd) {
                                            padding-right: 15px;

                                            a:before {
                                                background-image: url(/images/angle180/icon-paid.svg);
                                            }
                                        }

                                        &:nth-child(even) {
                                            padding-right: 0;
                                            padding-left: 15px;
                                        }

                                        a {
                                            padding-left: 40px;
    
                                            &:before {
                                                content: "";
                                                display: inline-block;
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                height: 30px;
                                                width: 30px;
                                                background-repeat: no-repeat;
                                                background-size: contain;
                                                background-position: left top;
                                                background-image: url(/images/angle180/icon-calculate.svg);
                                            }

                                            @media (min-width: 992px) and (max-width: 1023px) {
                                                padding-left: 32px;

                                                &:before {
                                                    height: 22px;
                                                    width: 22px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .hey-dropdown-box {
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    border: none;
                    box-shadow: none;
                    width: 100%;
                }
            }
        }

        .hey-dropdown-box > div .box-other-services + .hey-dropdown-box > div {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        }
    
        .hey-nav-separator.box-other-services {
            padding: 0 0 10px;
        }

        .item-title.box-other-services {
            color: $color_text_primary;
        }
    }

    .hey-dropdown-nav .hey-dropdown-menu>div.item-132 {

        > a {
            position: relative;
        }

        &:hover>.hey-dropdown-box {
            max-width: 520px;
            margin-left: auto!important;
            margin-right: 0!important;
        }

        > .hey-dropdown-box > div {
            flex: 0 0 100%;
            max-width: 100%;
        }

        .hey-dropdown-box {
            background-color: $white!important;

            > div .box-other-services+.hey-dropdown-box>div a {
                font-size: 19px!important;
                padding-top: 10px!important;
                padding-bottom: 10px!important;
            }

            > div .box-other-services+.hey-dropdown-box>div:nth-child(even) {
                padding-left: 45px;
            }
        }

        div.item-180 a {
            padding-top: 0!important;
            padding-bottom: 0!important;
        }
    }

    .hey-dropdown-nav .hey-dropdown-menu>div.item-127,
    .hey-dropdown-nav .hey-dropdown-menu>div.item-128 {

        div > a {
            font-size: 19px!important;
            font-weight: 500!important;
            padding-top: 20px!important;
            padding-bottom: 20px!important;
        }
    }
}

.menu-item-career:after {
    content: "Now Hiring!"!important;
    display: inline-block !important;
    position: relative;
    top: -1px;
    font-size: 12px;
    margin-left: 10px;
    padding: 0 8px;
    line-height: 24px;
    border-radius: 4px;
    color: $color_white!important;
    background-color: $color_secondary;
}

.menu-item-career-mob {
    @extend .menu-item-career;

    &:after {
        background-color: $color_gray_dark;
    }
}

@media (min-width: 768px) and (max-width: 991px) {

    .hey-dropdown-nav .hey-dropdown-menu > div.item-122 > .hey-dropdown-box,
    .hey-dropdown-nav .hey-dropdown-menu > div.item-140 > .hey-dropdown-box {
        left: -190px;
    }
}