.btn {
    cursor: pointer!important;
    display: inline-block;
    position: relative;
    box-shadow: none;
    border: none;
    background: transparent;
    font-size: 17px;
    line-height: 28px;
    text-decoration: none;
    @include transition(all 300ms ease-in-out);

    &:hover {
        @include transition(all 300ms ease-in-out);
        text-decoration: none;
    }

    &:before {
        display: none;
    }

    &:focus {
        outline: 0;
    }
}

.btn-block {

    > *:not(:last-child) {
        margin-right: 15px;
    }

    @include mq_mobile_portrait {
        
        > *:not(:last-child) {
            margin-bottom: 15px;
        }
    }
}

.btn-full {
    @extend .btn;
    font-family: $font_family_secondary;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 30px;
    color: $color_white;
    text-transform: uppercase;

    &:hover,
    &:focus {
        color: $color_white;
    }

    &--primary {
        @extend .btn-full;
        background-color: $color_primary;

        &:hover {
            background-color: darken($color_primary, 10%);
        }
    }

    &--secondary {
        @extend .btn-full;
        background-color: $color_secondary;

        &:hover {
            background-color: darken($color_secondary, 10%);
        }
    }

    &--gray {
        @extend .btn-full;
        background-color: $color_gray_dark;

        &:hover {
            background-color: darken($color_gray_dark, 10%);
        }
    }
}

.btn-border {
    @extend .btn;
    font-family: $font_family_secondary;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 30px;
    border: 1px solid $color_primary;
    // color: $color_white;
    text-transform: uppercase;

    &:hover,
    &:focus {
        color: $color_white;
    }

    &--primary {
        @extend .btn-border;
        color: $color_primary;
        border: 1px solid $color_primary;

        &:hover {
            color: $color_white;
            background-color: $color_primary;
        }
    }

    &--secondary {
        @extend .btn-border;
        color: $color_secondary;
        border: 1px solid $color_secondary;

        &:hover {
            color: $color_white;
            background-color: $color_secondary;
        }
    }

    &--gray {
        @extend .btn-border;
        color: $color_gray_dark;
        border: 1px solid $color_gray_dark;

        &:hover {
            color: $color_white;
            background-color: $color_gray_dark;
        }
    }
}

.btn-arrow {
    @extend .btn;
    font-size: 16px;
    color: $color_text_primary;
    font-family: $font_family_secondary;
    @include transition(all 300ms ease-in-out);
    
    @include hover-state {
        color: darken($color_text_primary, 20%);
        @include transition(all 300ms ease-in-out);

        span {
            border: 1px solid darken($color_text_primary, 20%);
            @include transition(all 300ms ease-in-out);

            i {
                -webkit-animation-name: forward-backward;
                animation-name: forward-backward;
                -webkit-animation-duration: .5s;
                animation-duration: .5s;
                -webkit-animation-iteration-count: 1;
                animation-iteration-count: 1;
            }
        }
    }

    span {
        display: inline-block;
        position: relative;
        top: 7px;
        left: 4px;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        text-align: left;
        border: 1px solid $color_text_primary;
        @include transition(all 300ms ease-in-out);

        i {
            display: inline-block;
            position: relative;
            top: -7px;
            left: 2px;
            margin-left: 3px;

            &:before,
            &:after {
                content: "";
                display: block;
                position: relative;
                height: 5px;
                width: 1px;
                background-color: $color_text_primary;
            }

            &:before {
                @include transform(rotate(-45deg));
            }

            &:after {
                top: -2px;
                @include transform(rotate(45deg));
            }
        }
    }

    &.white {
        color: $color_white;

        span {
            border: 1px solid $color_white;

            i {

                &:before,
                &:after {
                    background-color: $color_white;
                }
            }
        }
    }
}

.btn-hamburger {
    @extend .btn;
    display: none;
    position: absolute;
    top: 3px;
    right: 0;
    height: 44px;
    width: 44px;
    padding: 0;
    background-color: transparent;
    animation-fill-mode: forwards!important;
    opacity: 1!important;
    @include transition(background-color 600ms ease-in-out);

    &:after {
        content: "menu";
        display: block;
        font-size: 11px;
        font-weight: 300;
        line-height: 8px;
        color: $color_text_primary;
        @include transition(all 600ms ease-in-out);
    }

    .bar1, .bar2, .bar3 {
        display: block;
        width: 24px;
        height: 2px;
        background-color: $color_primary;
        margin: 4px auto;
        @include transition(all 600ms ease-in-out);
    }

    &:focus, &:active {
        outline-color: $color_secondary;
    }

    &.bg {
        background-color: $color_black!important;
    }

    &.active {
        background-color: transparent;
        z-index: $z_menu_mobile + 1;
        // right: 270px;
        // background-color: $color_black!important;
        
        &:after {
            content: "close";
            @include transition(all 300ms ease-in-out);
        }
        
        .bar1, .bar2, .bar3 {
            background-color: $color_primary;
            @include transition(all 600ms ease-in-out);
        }

        .bar1 {
            @include transform(rotate(-45deg) translate(1px, -1px));
        }

        .bar2 {opacity: 0;}

        .bar3 {
            @include transform(rotate(45deg) translate(-5px, -5px));
        }
    }

    @include hover-state {
        outline: none;
    }

    @media screen and (max-width: 991px) {
        display: block;
        z-index: $z_menu_mobile;
    }
}