.commercial-properties {
    
    .padding-inner {
        position: relative;
        z-index: 2;
        margin: -75px 15px 0;
    }

    ul {

        li {
            padding: 8px 0;
            border-bottom: 1px solid $color_border;
        }
    }

    @include mq_mobile {
        margin-top: 20px;

        .padding-inner {
            margin: 0;
        }
    }
}

.sidebar-latest-posts {

    @include mq_mobile {
        margin-top: 50px;
    }
}

@media screen and (min-width: 768px) {

    .sidebar-w-img > .padding-inner {
        position: relative;
        z-index: 2;
        margin: -75px 15px 0;
    }
}