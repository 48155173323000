// // Heading overrides
h1 {
    @extend .font-heading--extra_large;
}

h2 {
    @extend .font-heading--large;
}

h3 {
    @extend .font-heading--small;
}

h4 {
    @extend .font-heading--extra_small;
}

h5 {
    @extend .font-heading--extra_small;
}

h6 {
    @extend .font-heading--extra_small;
}



// // Heading font classes
.font-heading--extra_large {
    font-family: $font_family_secondary;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    color: $color_text_secondary;

    @include mq_mobile {
        font-size: 38px;
        line-height: 48px;
    }
}

.font-heading--large {
    font-family: $font_family_secondary;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.2;
    color: $color_text_secondary;
}

.font-heading--medium {
    font-family: $font_family_secondary;
    font-weight: 500;
    font-size: 24px;
    color: $color_text_secondary;
}

.font-heading--small {
    font-family: $font_family_secondary;
    font-weight: 500;
    font-size: 22px;
    color: $color_text_secondary;
}

.font-heading--extra_small {
    font-family: $font_family_secondary;
    font-weight: 500;
    color: $color_text_secondary;
}


// // Paragraph font clasess
.font-paragraph--extra_large {
    font-size: 24px;
    line-height: 1.4;
}

.font-paragraph--large {
    font-size: 21px;
    line-height: 1.4;
}

.font-paragraph--medium {
    font-size: 19px;
}

.font-paragraph--body {
    font-size: 17px;
}

.font-paragraph--small {
    font-size: 14px;
}

.font-paragraph--extra_small {
    font-size: 12px;
}

.subheading {
    margin-top: 10px!important;
    margin-bottom: 30px!important;
    font-weight: 400;
    font-style: italic;

    &:after {
        display: none!important;
    }
}

// @import url('/templates/angle180/fonts/https://fonts.googleapis.com/css?family=Dosis:500,600,700|Roboto:300,500,700');
// @font-face {
//     font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-LightItalic.eot');
//     src: local('Roboto Light Italic'), local('Roboto-LightItalic'),
//         url('/templates/angle180/fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-LightItalic.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-LightItalic.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-LightItalic.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
//     font-weight: 300;
//     font-style: italic;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-Black.eot');
//     src: local('Roboto Black'), local('Roboto-Black'),
//         url('/templates/angle180/fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-Black.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-Black.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-Black.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-Black.svg#Roboto-Black') format('svg');
//     font-weight: 900;
//     font-style: normal;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-BoldItalic.eot');
//     src: local('Roboto Bold Italic'), local('Roboto-BoldItalic'),
//         url('/templates/angle180/fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-BoldItalic.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-BoldItalic.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-BoldItalic.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
//     font-weight: bold;
//     font-style: italic;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Dosis';
//     src: url('/templates/angle180/fonts/Dosis-SemiBold.eot');
//     src: local('Dosis SemiBold'), local('Dosis-SemiBold'),
//         url('/templates/angle180/fonts/Dosis-SemiBold.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Dosis-SemiBold.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Dosis-SemiBold.woff') format('woff'),
//         url('/templates/angle180/fonts/Dosis-SemiBold.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Dosis-SemiBold.svg#Dosis-SemiBold') format('svg');
//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-display: block;
//     font-family: 'Dosis';
//     src: url('/templates/angle180/fonts/Dosis-Regular.eot');
//     src: local('Dosis Regular'), local('Dosis-Regular'),
//         url('/templates/angle180/fonts/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Dosis-Regular.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Dosis-Regular.woff') format('woff'),
//         url('/templates/angle180/fonts/Dosis-Regular.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Dosis-Regular.svg#Dosis-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-display: block;
//     font-family: 'Dosis';
//     src: url('/templates/angle180/fonts/Dosis-Medium.eot');
//     src: local('Dosis Medium'), local('Dosis-Medium'),
//         url('/templates/angle180/fonts/Dosis-Medium.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Dosis-Medium.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Dosis-Medium.woff') format('woff'),
//         url('/templates/angle180/fonts/Dosis-Medium.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Dosis-Medium.svg#Dosis-Medium') format('svg');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-display: block;
//     font-family: 'Dosis';
//     src: url('/templates/angle180/fonts/Dosis-Bold.eot');
//     src: local('Dosis Bold'), local('Dosis-Bold'),
//         url('/templates/angle180/fonts/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Dosis-Bold.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Dosis-Bold.woff') format('woff'),
//         url('/templates/angle180/fonts/Dosis-Bold.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Dosis-Bold.svg#Dosis-Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-Bold.eot');
//     src: local('Roboto Bold'), local('Roboto-Bold'),
//         url('/templates/angle180/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-Bold.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-Bold.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-Bold.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-Bold.svg#Roboto-Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-Thin.eot');
//     src: local('Roboto Thin'), local('Roboto-Thin'),
//         url('/templates/angle180/fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-Thin.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-Thin.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-Thin.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-Thin.svg#Roboto-Thin') format('svg');
//     font-weight: 100;
//     font-style: normal;
// }

// @font-face {
//     font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-Regular.eot');
//     src: local('Roboto'), local('Roboto-Regular'),
//         url('/templates/angle180/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-Regular.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-Regular.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-Regular.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-Light.eot');
//     src: local('Roboto Light'), local('Roboto-Light'),
//         url('/templates/angle180/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-Light.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-Light.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-Light.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-Light.svg#Roboto-Light') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Dosis';
//     src: url('/templates/angle180/fonts/Dosis-ExtraLight.eot');
//     src: local('Dosis ExtraLight'), local('Dosis-ExtraLight'),
//         url('/templates/angle180/fonts/Dosis-ExtraLight.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Dosis-ExtraLight.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Dosis-ExtraLight.woff') format('woff'),
//         url('/templates/angle180/fonts/Dosis-ExtraLight.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Dosis-ExtraLight.svg#Dosis-ExtraLight') format('svg');
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-ThinItalic.eot');
//     src: local('Roboto Thin Italic'), local('Roboto-ThinItalic'),
//         url('/templates/angle180/fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-ThinItalic.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-ThinItalic.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-ThinItalic.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
//     font-weight: 100;
//     font-style: italic;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-BlackItalic.eot');
//     src: local('Roboto Black Italic'), local('Roboto-BlackItalic'),
//         url('/templates/angle180/fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-BlackItalic.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-BlackItalic.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-BlackItalic.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
//     font-weight: 900;
//     font-style: italic;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Dosis';
//     src: url('/templates/angle180/fonts/Dosis-ExtraBold.eot');
//     src: local('Dosis ExtraBold'), local('Dosis-ExtraBold'),
//         url('/templates/angle180/fonts/Dosis-ExtraBold.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Dosis-ExtraBold.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Dosis-ExtraBold.woff') format('woff'),
//         url('/templates/angle180/fonts/Dosis-ExtraBold.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Dosis-ExtraBold.svg#Dosis-ExtraBold') format('svg');
//     font-weight: 800;
//     font-style: normal;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Dosis';
//     src: url('/templates/angle180/fonts/Dosis-Light.eot');
//     src: local('Dosis Light'), local('Dosis-Light'),
//         url('/templates/angle180/fonts/Dosis-Light.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Dosis-Light.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Dosis-Light.woff') format('woff'),
//         url('/templates/angle180/fonts/Dosis-Light.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Dosis-Light.svg#Dosis-Light') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-Italic.eot');
//     src: local('Roboto Italic'), local('Roboto-Italic'),
//         url('/templates/angle180/fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-Italic.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-Italic.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-Italic.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-Italic.svg#Roboto-Italic') format('svg');
//     font-weight: normal;
//     font-style: italic;
// }

// @font-face {
//     font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-Medium.eot');
//     src: local('Roboto Medium'), local('Roboto-Medium'),
//         url('/templates/angle180/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-Medium.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-Medium.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-Medium.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
    // font-display: block;
//     font-family: 'Roboto';
//     src: url('/templates/angle180/fonts/Roboto-MediumItalic.eot');
//     src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
//         url('/templates/angle180/fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
//         url('/templates/angle180/fonts/Roboto-MediumItalic.woff2') format('woff2'),
//         url('/templates/angle180/fonts/Roboto-MediumItalic.woff') format('woff'),
//         url('/templates/angle180/fonts/Roboto-MediumItalic.ttf') format('truetype'),
//         url('/templates/angle180/fonts/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
//     font-weight: 500;
//     font-style: italic;
// }