$icomoon-font-family: "iconfont" !default;
$icomoon-font-path: "/templates/angle180/fonts" !default;

$icon-healthcare: "\e912";
$icon-hospitality: "\e913";
$icon-office: "\e915";
$icon-retail: "\e916";
$icon-phone: "\e92e";
$icon-quote: "\e937";
$icon-document: "\e9cd";
$icon-envelope: "\e9d3";
$icon-client: "\e906";
$icon-fast: "\e90c";
$icon-mechanic: "\e90f";
$icon-performance: "\e914";
$icon-repair: "\e918";
$icon-calendar: "\e900";
$icon-cap: "\e901";
$icon-cleaning: "\e902";
$icon-qualified: "\e903";
$icon-time-left: "\e904";
$icon-architects: "\e905";
$icon-engineer: "\e907";
$icon-exterior: "\e908";
$icon-hoa-managers: "\e909";
$icon-houses: "\e90a";
$icon-interior: "\e90b";
$icon-location: "\e90d";
$icon-office-block: "\e90e";
$icon-paintbrush: "\e910";
$icon-shield: "\e911";



@font-face {
    font-family: '#{$icomoon-font-family}';
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tym3ob');
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?tym3ob#iefix') format('embedded-opentype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?tym3ob') format('truetype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?tym3ob') format('woff'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?tym3ob##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  i {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    // speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-healthcare {
    &:before {
      content: $icon-healthcare; 
    }
  }
  .icon-hospitality {
    &:before {
      content: $icon-hospitality; 
    }
  }
  .icon-office {
    &:before {
      content: $icon-office; 
    }
  }
  .icon-retail {
    &:before {
      content: $icon-retail; 
    }
  }
  .icon-phone {
    &:before {
      content: $icon-phone; 
    }
  }
  .icon-quote {
    &:before {
      content: $icon-quote; 
    }
  }
  .icon-document {
    &:before {
      content: $icon-document; 
    }
  }
  .icon-envelope {
    &:before {
      content: $icon-envelope; 
    }
  }
  .icon-client {
    &:before {
      content: $icon-client; 
    }
  }
  .icon-fast {
    &:before {
      content: $icon-fast; 
    }
  }
  .icon-mechanic {
    &:before {
      content: $icon-mechanic; 
    }
  }
  .icon-performance {
    &:before {
      content: $icon-performance; 
    }
  }
  .icon-repair {
    &:before {
      content: $icon-repair; 
    }
  }
  .icon-calendar {
    &:before {
      content: $icon-calendar; 
    }
  }
  .icon-cap {
    &:before {
      content: $icon-cap; 
    }
  }
  .icon-cleaning {
    &:before {
      content: $icon-cleaning; 
    }
  }
  .icon-qualified {
    &:before {
      content: $icon-qualified; 
    }
  }
  .icon-time-left {
    &:before {
      content: $icon-time-left; 
    }
  }
  .icon-architects {
    &:before {
      content: $icon-architects; 
    }
  }
  .icon-engineer {
    &:before {
      content: $icon-engineer; 
    }
  }
  .icon-exterior {
    &:before {
      content: $icon-exterior; 
    }
  }
  .icon-hoa-managers {
    &:before {
      content: $icon-hoa-managers; 
    }
  }
  .icon-houses {
    &:before {
      content: $icon-houses; 
    }
  }
  .icon-interior {
    &:before {
      content: $icon-interior; 
    }
  }
  .icon-location {
    &:before {
      content: $icon-location; 
    }
  }
  .icon-office-block {
    &:before {
      content: $icon-office-block; 
    }
  }
  .icon-paintbrush {
    &:before {
      content: $icon-paintbrush; 
    }
  }
  .icon-shield {
    &:before {
      content: $icon-shield; 
    }
  }
  
  