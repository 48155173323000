.hero {

    > .container-extended {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom right;

        @include mq_mobile {
            background-position: bottom right -310px;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba($color_black, .5);
            background: linear-gradient(90deg, rgba($color_blue_dark,1) 0%, rgba($color_blue_dark,1) 30%, rgba(0,212,255,0) 100%);
        }
    }

    &-img {
        position: absolute;
        top: calc(50% - 30px);
        right: 20px;
        width: 202px;
        padding-top: 17.5%;
        @include transform(translateY(-50%));

        img {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            object-fit: contain;
        }

        @media (min-width: 568px) and (max-width: 991px) {
            top: calc(50% + 70px);
        }

        @include mq_mobile_portrait {
            display: none;
        }
    }

    &-content {

        .hero-title {
            @extend .font-heading--extra_large;
            @extend .color--white;
            font-size: 60px;
            line-height: 80px;

            @include mq_mobile {
                font-size: 44px;
                line-height: 50px;
            }
        }

        @extend .color--white;
        position: relative;
        padding: 70px 0 180px;
        z-index: 2;

        p {
            max-width: 730px;
        }

        @include mq_mobile {
            padding: 90px 0 180px;
        }
    }

    &-inner {
        @extend .hero;

        > .container-extended {
            overflow: hidden;
        }

        picture {

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                z-index: -1;
                @include transform(translate(-50%, -50%));

                @include mq_mobile {
                    height: 100%;
                }
            }
        }

        .hero-content {
            padding: 110px 0;
        }
    }
}